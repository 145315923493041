import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertConfig } from '../shared/alert/alert.component';
import { User } from './auth.service';
import { Settings } from './settings.service';

export interface Store {
  alert?: AlertConfig;
  appTitle: string;
  user?: User;
  settings?: Settings;
}

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly _store = new BehaviorSubject<Store>({
    appTitle: 'Carta Ya',
  });
  readonly store$ = this._store.asObservable();

  setAlert(alert: AlertConfig): void {
    this._store.next({ ...this._store.getValue(), alert });
  }

  setAppTitle(appTitle: string): void {
    this._store.next({ ...this._store.getValue(), appTitle });
  }

  setUser(user: User): void {
    this._store.next({ ...this._store.getValue(), user });
  }

  setSettings(settings: Settings): void {
    this._store.next({ ...this._store.getValue(), settings });
  }
}
