import { Environment } from "./environment.interface";

// apiUrl is the url of the backend, should never end with a slash
export const environment: Environment = {
    appName: 'Carta Ya',
    production: true,
    apiUrl: 'https://cartaya-backend.backoffice-dd1.workers.dev',
    login: {
        backgroundImage: 'https://res.cloudinary.com/dbsyo8whb/image/upload/v1724618792/pexels-chudesabyvaut-1351350-2612334_huq352.jpg'
    },
    register: {
        backgroundImage: 'https://res.cloudinary.com/dbsyo8whb/image/upload/v1724618792/pexels-necip-duman-3299706-27349262_tquhdr.jpg'
    },
    socialLinks: {
        instagram: 'https://www.instagram.com/cartayaapp/',
    }
};